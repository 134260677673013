<template>
  <Popover class="relative inline-block">
    <PopoverButton
      v-if="show"
      :class="`items-center gap-[5px] px-1.5 rounded-[3px] bg-white focus:outline-none ${badgeClass} ${badgeStyle}`"
    >
      <svg v-if="state === 'partner'" viewBox="0 0 12 12" fill="currentColor" :class="iconSize">
        <path fill="#2AC644" fill-rule="nonzero" d="m2.341 12 1.4-4.53L0 4.543h4.556L6.032 0l1.476 4.543h4.556L8.322 7.47 9.735 12l-3.69-2.812z"/>
      </svg>
      <svg v-else viewBox="0 0 12 12" fill="currentColor" :class="iconSize">
        <path fill="#F55" fill-rule="nonzero" d="M5.991 9.237c.276 0 .507-.09.693-.27a.912.912 0 0 0 .279-.684.98.98 0 0 0-.27-.7.9.9 0 0 0-.684-.285.937.937 0 0 0-.693.28.945.945 0 0 0-.279.694c0 .276.09.505.27.69.181.183.409.275.684.275Zm-.884-2.553h1.786V2.986H5.107v3.698ZM6.004 12a5.983 5.983 0 0 1-2.34-.462 5.935 5.935 0 0 1-1.92-1.282A5.988 5.988 0 0 1 0 5.993c0-.831.154-1.61.463-2.336A5.97 5.97 0 0 1 6.006 0c.832 0 1.611.154 2.338.462a5.968 5.968 0 0 1 3.194 3.198c.308.728.462 1.508.462 2.337a5.983 5.983 0 0 1-3.66 5.54A5.927 5.927 0 0 1 6.004 12Z"/>
      </svg>
      <span :class="`${contentClass} font-medium uppercase`">
        {{ state }}
      </span>
      <svg viewBox="0 0 12 12" fill="currentColor" class="md:hidden h-3">
        <path fill="#B8B5C4" fill-rule="nonzero" d="M6 9.75a.723.723 0 0 0 .531-.219A.723.723 0 0 0 6.75 9a.723.723 0 0 0-.219-.531A.723.723 0 0 0 6 8.25a.723.723 0 0 0-.531.219A.723.723 0 0 0 5.25 9c0 .208.073.385.219.531A.723.723 0 0 0 6 9.75Zm-.563-2.39h1.141c0-.386.034-.66.102-.82.067-.162.231-.368.492-.618.364-.354.617-.656.758-.906.14-.25.21-.526.21-.828 0-.573-.195-1.04-.585-1.399-.39-.36-.888-.539-1.492-.539-.532 0-.993.14-1.383.422-.39.281-.664.661-.82 1.14l1.015.422a1.38 1.38 0 0 1 .445-.68 1.11 1.11 0 0 1 .711-.241c.292 0 .532.083.719.25a.836.836 0 0 1 .281.656c0 .24-.08.453-.242.64a5.91 5.91 0 0 1-.539.547c-.365.334-.591.607-.68.82-.088.214-.133.592-.133 1.133ZM6 12a5.847 5.847 0 0 1-2.328-.469 6.048 6.048 0 0 1-1.914-1.289 6.048 6.048 0 0 1-1.29-1.914A5.847 5.847 0 0 1 0 6c0-.833.156-1.612.469-2.336A6.048 6.048 0 0 1 3.672.47 5.847 5.847 0 0 1 6 0a5.83 5.83 0 0 1 2.336.469 6.072 6.072 0 0 1 3.195 3.195A5.83 5.83 0 0 1 12 6c0 .823-.156 1.599-.469 2.328a6.048 6.048 0 0 1-3.195 3.203A5.83 5.83 0 0 1 6 12Zm0-1.125c1.354 0 2.505-.474 3.453-1.422S10.875 7.354 10.875 6c0-1.354-.474-2.505-1.422-3.453S7.354 1.125 6 1.125c-1.354 0-2.505.474-3.453 1.422S1.125 4.646 1.125 6c0 1.354.474 2.505 1.422 3.453S4.646 10.875 6 10.875Z"/>
      </svg>
    </PopoverButton>

    <transition
      enter-active-class="transition duration-300 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-200 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <PopoverPanel class="fixed md:absolute z-50 inset-0 md:inset-auto flex items-center p-10 md:p-2.5 md:bottom-[126%] w-screen md:max-w-[224px] md:shadow-tooltip text-base md:text-[13px] leading-6 md:leading-[18px] text-center md:text-left text-primaryText bg-[#e8f4ff]/90 md:bg-[#e8f4ff] backdrop-blur rounded-lg">
        {{
          state === 'partner'
            ? 'This provider has been verified by Megeni and is a trusted partner. Expect prompt and reliable responses to inquiries submitted via Megeni.com.'
            : 'This provider is not verified by Megeni. They may or may not respond to inquiries submitted via Megeni.com.'
        }}
        <PopoverButton class="md:hidden absolute top-5 right-5 rounded-full">
          <svg fill="none" viewBox="0 0 24 24" stroke-width="2.2" stroke="currentColor" class="w-7 h-7">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </PopoverButton>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script setup>
import { Popover, PopoverButton, PopoverPanel, provideUseId } from '@headlessui/vue'
provideUseId(() => useId())

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  state: {
    type: [String, undefined],
    required: true,
  },
  badgeClass: {
    type: String,
    required: true
  },
  contentClass: {
    type: String,
    required: true
  },
  iconSize: {
    type: String,
    default: 'h-3'
  },
})

// const show = computed(() => props.state === 'partner' || props.state === 'unverified')
const badgeStyle = computed(() => props.state === 'partner' ? 'text-partner' : 'text-unverified')

</script>
